




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

import api from "@/api/api";
import { Loader } from "@googlemaps/js-api-loader";
import {
  Organization,
  OrganizationUserAuthorization,
  ParkingLotCreate,
  ParkingLotBasic,
  ParkingLotUpdate,
  LprBlacklistWhitelistMode,
} from "@/api/models";
import { Point, FeatureCollection, Polygon } from "geojson";
import {
  extractHHmmFromHHmmss,
  convertHHmmLocalToUtc,
  convertHHmmUtcToLocal,
  convert24HourToAmPm,
  checkIfAllWeekCrontabTimesAreEqual,
  checkIfAllWeekDaysCrontabTimesAreEqual,
  convertTimeToCrontab,
  convertCrontabToTime,
} from "@/libs/dateUtils";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "ParkingLotForm",

  props: {
    existingLotDetails: {
      type: Object as () => ParkingLotBasic,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    lotId: null as number | null,
    name: "",
    address: "",
    lat: 0,
    lng: 0,
    polygon: null as Polygon | null,
    max_park_minutes: null as number | null,
    congestion_threshold: null as number | null,
    runInference: false,
    isPrivate: false,
    enableSearchParkedCarOwner: false,
    enableSpotUpdateNotification: false,
    places: {
      selectedItem: null as google.maps.places.AutocompletePrediction | null,
      searchText: "",
      items: [] as Array<google.maps.places.AutocompletePrediction>,
      isLoading: false,
    },
    maps: {
      autoCompleteService:
        null as google.maps.places.AutocompleteService | null,
      map: null as google.maps.Map | null,
      placesService: null as google.maps.places.PlacesService | null,
    },
    organizations: {
      selectedOrgId: 0,
      allOrgs: [] as Array<OrganizationUserAuthorization>,
      isLoadingAllOrgs: false,
    },
    tagNames: [] as Array<string>,
    isLoading: false,
    uniqueParkingLotNameError: false,

    isSecurityFeatureEnabled: false,
    illegalActivityDetection: {
      isEnabled: false,
      sameTimeOnAllDays: false,
      selectedDayIndex: 0,
      startsAt: {
        time: "" as string | null,
        timesPerDay: Array(7).fill("") as Array<string>,
      },
      endsAt: {
        time: "" as string | null,
        timesPerDay: Array(7).fill("") as Array<string>,
      },
      vehicleCountThreshold: 10 as number | null,
      isAddVehiclesToThresholdEnabled: false,
      vehicleCountAtStart: 0 as number,
    },

    isIllegalParkingViolationFeatureEnabled: false,
    violationAlertGracePeriodSeconds: 180 as number,

    isParkingPermitFeatureEnabled: false,
    isParkMyCarEnabled: false,
    showUsersProfile: false,
    showBlockingUsersProfile: true,
    waitForUserToSave: 5,
    isSpotVisibilityCheckingFeatureEnabled: false,
    isAccuracyTrackingFeatureEnabled: false,
    isExtremeWeatherDetectionFeatureEnabled: false,
    isGarbledImageDetectionFeatureEnabled: false,
    isParkingHistoryVisible: false,
    cameraOfflineAlertDelayThresholdMinutes: 2 as number | null,
    inferenceHeartbeatIntervalSeconds: 120,
    showCameraROIWarning: false,
    isPullApisEnabled: false,
    isEnforceabilityPushApisEnabled: false,
    isAvailabilityPushApisEnabled: false,
    isDoubleParkingFeatureEnabled: false,
    doubleParkingViolationWaitTimeSeconds: 180,
    isEvChargerViolationFeatureEnabled: false,
    evViolationCheckIntervalMinutes: 6,
    evViolationCountForAlert: 2,
    isRevenueFeatureEnabled: false,
    isSGConnectFeatureEnabled: false,
    revenueTimeUnits: [
      { name: "Mins", value: "min" },
      { name: "Hrs", value: "hour" },
    ],
    revenueOptions: {
      minimum: {
        time: 0,
        unit: "min",
        amount: 0,
      },
      increments: {
        time: 0,
        unit: "min",
        amount: 0,
      },
      hourly_amount: 0,
      daily_amount: 0,
      daily_max_amount: 0,
      violationFines: {
        non_payment: 0,
        overstay: 0,
        illegal_parking: 0,
        permit: 0,
      },
    },
    revenueOptionsError: null as string | null,
    violationFinesError: null as string | null,

    lpr: {
      isFeatureEnabled: false,
      blacklistWhitelistMode: [
        { name: "Blacklist Mode", value: "blacklist_mode" },
        { name: "Whitelist Mode", value: "whitelist_mode" },
      ],
      isLprAlertListFeatureEnabled: false,
      lprEntryExitPairLinkingIntervalMinutes: 1440,
      lprNumberPlateTextMatchingDistanceThresh: 2,
      selectedBlacklistWhitelistMode: null as LprBlacklistWhitelistMode | null,
      isFlockLPRCamerasEnabled: false,
    },

    anpr: {
      isFeatureEnabled: false,
      isVisibleToCustomers: false,
      matchingWindowIntervalMinutes: 10 as number,
      inactiveCleanupIntervalMinutes: 10 as number,
      vehicleMatchingThreshold: 78 as number,
      isIgnoreDuplicateLpWithinIntervalEnabled: false,
      lprEventDelayOffsetSeconds: 0,
      isRectifyDelayedLprEnabled: false,
    },

    zoneVehicleCountReset: {
      sameTimeOnAllDays: true,
      selectedDayIndex: 0,
      startsAt: {
        time: "00:00",
        showField: true,
        timesPerDay: Array(7).fill("00:00") as Array<string>,
      },
    },

    lotImage: {
      path: null as string | null,
      thumbnailPath: null as string | null,
      url: null as string | null,
      thumbnailUrl: null as string | null,
      isUploading: false,
    },

    parkingTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
      enabled: false,
    },
    sgConnectLotOptions: {
      paidParkingTimingConfig: false,
      parkingTimingStartFrom: 0,
      parkingTimingEndTo: 0,
      enforcedParkingRulesTiming: "",
      overstayLimit: 0,
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "getCurrentUserData"]),

    isUpdatingExistingLot(): boolean {
      return this.lotId != null;
    },

    // check if time has been provided for all days
    validateIllegalActivityTime(): boolean {
      if (
        this.isSecurityFeatureEnabled &&
        this.illegalActivityDetection.isEnabled
      ) {
        if (
          this.illegalActivityDetection.startsAt.time == "" ||
          this.illegalActivityDetection.endsAt.time == ""
        ) {
          return false;
        }
        if (
          this.illegalActivityDetection.startsAt.timesPerDay.includes("") ||
          this.illegalActivityDetection.endsAt.timesPerDay.includes("")
        ) {
          return false;
        }
      }
      return true;
    },

    maxParkTimeSeconds(): number | null {
      return Number(
        Number(this.parkingTime.hours * 3600) +
          Number(this.parkingTime.minutes * 60) +
          Number(this.parkingTime.seconds)
      );
    },
  },

  async mounted() {
    this.initMaps();
    if (this.isSuperAdmin) {
      await this.fetchAllOrganizations();
    }
    this.initFormWithLotDetails(this.existingLotDetails);
  },

  methods: {
    ...mapActions("sgconnectStore", ["generateTokenApi", "parkingTimingApi"]),
    convert24HourToAmPm,

    async initMaps() {
      const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["drawing", "places"],
      });

      // Wait for google maps to initialize/load.
      await loader.load();

      this.maps.autoCompleteService =
        new google.maps.places.AutocompleteService();
      this.maps.map = new google.maps.Map(
        document.getElementById("places-map") as HTMLElement,
        {
          center: { lat: 0, lng: 0 },
          zoom: 20,
        }
      );

      this.maps.placesService = new google.maps.places.PlacesService(
        this.maps.map
      );
    },

    initFormWithLotDetails(existingLotDetails?: ParkingLotBasic) {
      if (existingLotDetails) {
        console.log("Init parking lot details", existingLotDetails);
        this.lotId = existingLotDetails.id;
        this.name = existingLotDetails.name;
        this.address = existingLotDetails.address;
        this.organizations.selectedOrgId = existingLotDetails.organization_id;
        this.runInference = existingLotDetails.run_inference;
        this.isPrivate = existingLotDetails.is_private;
        this.enableSearchParkedCarOwner =
          existingLotDetails.is_search_parked_car_owner_enabled;
        this.enableSpotUpdateNotification =
          existingLotDetails.is_notification_on_spot_update_enabled;
        if (existingLotDetails.tag_names) {
          this.tagNames = existingLotDetails.tag_names;
        }
        if (existingLotDetails.max_park_minutes != null) {
          this.max_park_minutes = existingLotDetails.max_park_minutes;
          [
            this.parkingTime.hours,
            this.parkingTime.minutes,
            this.parkingTime.seconds,
          ] = this.getTimeFromSeconds(existingLotDetails.max_park_minutes * 60);
        } else {
          this.clearParkingTime();
        }
        if (existingLotDetails.congestion_threshold != null) {
          this.congestion_threshold = existingLotDetails.congestion_threshold;
        }
        const { coordinates } =
          typeof this.existingLotDetails.gps_coordinates === "string"
            ? JSON.parse(this.existingLotDetails.gps_coordinates)
            : this.existingLotDetails.gps_coordinates;
        this.lat = coordinates[1];
        this.lng = coordinates[0];
        this.polygon =
          typeof this.existingLotDetails.polygon === "string"
            ? JSON.parse(this.existingLotDetails.polygon)
            : this.existingLotDetails.polygon;

        // Security feature
        this.isSecurityFeatureEnabled =
          existingLotDetails.is_security_feature_enabled;
        this.illegalActivityDetection.isEnabled =
          existingLotDetails.is_illegal_activity_detection_enabled;
        this.illegalActivityDetection.sameTimeOnAllDays =
          checkIfAllWeekCrontabTimesAreEqual(
            this.existingLotDetails.illegal_activity_detection_week_start_time,
            this.existingLotDetails.illegal_activity_detection_week_end_time
          );
        if (
          this.existingLotDetails.illegal_activity_detection_week_start_time
        ) {
          this.illegalActivityDetection.startsAt.timesPerDay =
            this.existingLotDetails.illegal_activity_detection_week_start_time.map(
              (t) => convertHHmmUtcToLocal(extractHHmmFromHHmmss(t)) || ""
            );
        }
        if (this.existingLotDetails.illegal_activity_detection_week_end_time) {
          this.illegalActivityDetection.endsAt.timesPerDay =
            this.existingLotDetails.illegal_activity_detection_week_end_time.map(
              (t) => convertHHmmUtcToLocal(extractHHmmFromHHmmss(t)) || ""
            );
        }
        this.illegalActivityDetection.startsAt.time =
          this.illegalActivityDetection.startsAt.timesPerDay[
            this.illegalActivityDetection.selectedDayIndex
          ];
        this.illegalActivityDetection.endsAt.time =
          this.illegalActivityDetection.endsAt.timesPerDay[
            this.illegalActivityDetection.selectedDayIndex
          ];
        if (
          existingLotDetails.illegal_activity_detection_vehicle_count_threshold !=
          null
        ) {
          this.illegalActivityDetection.vehicleCountThreshold =
            existingLotDetails.illegal_activity_detection_vehicle_count_threshold;
        }
        if (
          existingLotDetails.is_illegal_activity_detection_set_initial_vehicle_count !=
          null
        ) {
          this.illegalActivityDetection.isAddVehiclesToThresholdEnabled =
            existingLotDetails.is_illegal_activity_detection_set_initial_vehicle_count;
        }
        if (
          existingLotDetails.illegal_activity_detection_vehicles_in_lot != null
        ) {
          this.illegalActivityDetection.vehicleCountAtStart =
            existingLotDetails.illegal_activity_detection_vehicles_in_lot;
        }

        this.isIllegalParkingViolationFeatureEnabled =
          existingLotDetails.is_illegal_parking_violation_feature_enabled;
        this.violationAlertGracePeriodSeconds =
          existingLotDetails.violation_alert_grace_period_seconds;

        this.isParkingPermitFeatureEnabled =
          existingLotDetails.is_parking_permit_feature_enabled;
        this.isParkMyCarEnabled =
          existingLotDetails.is_park_my_car_feature_enabled;
        this.showUsersProfile =
          existingLotDetails.is_show_app_user_info_enabled;
        this.showBlockingUsersProfile =
          existingLotDetails.is_show_blocking_app_user_info_enabled;
        this.waitForUserToSave = existingLotDetails.wait_for_user_to_save_spot;
        this.isSpotVisibilityCheckingFeatureEnabled =
          existingLotDetails.is_spot_visibility_checking_feature_enabled;
        this.isDoubleParkingFeatureEnabled =
          existingLotDetails.is_double_parking_feature_enabled;
        this.doubleParkingViolationWaitTimeSeconds =
          existingLotDetails.double_parking_violation_wait_time_seconds;
        this.isEvChargerViolationFeatureEnabled =
          existingLotDetails.is_ev_charger_violation_feature_enabled;
        this.evViolationCheckIntervalMinutes =
          existingLotDetails.ev_violation_check_interval_minutes;
        this.evViolationCountForAlert =
          existingLotDetails.ev_violation_count_for_alert;
        this.isAccuracyTrackingFeatureEnabled =
          existingLotDetails.is_accuracy_tracking_feature_enabled;
        this.isExtremeWeatherDetectionFeatureEnabled =
          existingLotDetails.is_extreme_weather_detection_feature_enabled;
        this.isGarbledImageDetectionFeatureEnabled =
          existingLotDetails.is_garbled_image_detection_feature_enabled;
        this.cameraOfflineAlertDelayThresholdMinutes =
          existingLotDetails.camera_offline_alert_delay_threshold_minutes;
        this.inferenceHeartbeatIntervalSeconds =
          existingLotDetails.inference_heartbeat_interval_seconds;
        this.lpr.isFeatureEnabled = existingLotDetails.is_lpr_feature_enabled;
        this.lpr.isLprAlertListFeatureEnabled =
          existingLotDetails.is_lpr_alert_list_feature_enabled;
        this.lpr.lprNumberPlateTextMatchingDistanceThresh =
          existingLotDetails.lpr_number_plate_text_matching_distance_thresh;
        this.lpr.lprEntryExitPairLinkingIntervalMinutes =
          existingLotDetails.lpr_pair_linking_interval_minutes;
        this.anpr.isFeatureEnabled = existingLotDetails.is_anpr_feature_enabled;
        this.anpr.isVisibleToCustomers =
          existingLotDetails.is_anpr_feature_visible_to_customers;
        this.anpr.matchingWindowIntervalMinutes =
          existingLotDetails.anpr_matching_window_interval_minutes;
        this.anpr.inactiveCleanupIntervalMinutes =
          existingLotDetails.anpr_inactive_cleanup_interval_minutes;
        this.anpr.vehicleMatchingThreshold =
          existingLotDetails.anpr_vehicle_matching_threshold;
        this.anpr.isIgnoreDuplicateLpWithinIntervalEnabled =
          existingLotDetails.is_anpr_ignore_duplicate_lp_within_interval_enabled;
        this.anpr.lprEventDelayOffsetSeconds =
          existingLotDetails.anpr_lpr_event_delay_offset_seconds;
        this.anpr.isRectifyDelayedLprEnabled =
          existingLotDetails.is_anpr_rectify_delayed_lpr_enabled;

        this.isParkingHistoryVisible =
          existingLotDetails.is_parking_history_visible;
        this.isPullApisEnabled =
          existingLotDetails.is_pull_apis_feature_enabled;
        this.isEnforceabilityPushApisEnabled =
          existingLotDetails.is_enforceability_push_apis_feature_enabled;
        this.isAvailabilityPushApisEnabled =
          existingLotDetails.is_availability_push_apis_feature_enabled;
        this.isRevenueFeatureEnabled =
          existingLotDetails.is_revenue_feature_enabled;
        this.isSGConnectFeatureEnabled =
          existingLotDetails.is_sg_connect_feature_enabled;
        this.revenueOptions =
          existingLotDetails.revenue_options != null
            ? existingLotDetails.revenue_options
            : this.revenueOptions;

        if (
          existingLotDetails.counter_zone_reset_vehicle_count_start_schedule_week_crontab
        ) {
          this.zoneVehicleCountReset.sameTimeOnAllDays =
            checkIfAllWeekDaysCrontabTimesAreEqual(
              existingLotDetails.counter_zone_reset_vehicle_count_start_schedule_week_crontab,
              null
            );
          this.zoneVehicleCountReset.startsAt.timesPerDay =
            existingLotDetails.counter_zone_reset_vehicle_count_start_schedule_week_crontab.map(
              (c) => convertCrontabToTime(c)
            );
          this.zoneVehicleCountReset.startsAt.time =
            this.zoneVehicleCountReset.startsAt.timesPerDay[
              this.zoneVehicleCountReset.selectedDayIndex
            ];
        } else {
          this.zoneVehicleCountReset.startsAt.time = "";
          this.zoneVehicleCountReset.startsAt.timesPerDay = Array(7).fill(
            ""
          ) as Array<string>;
        }

        this.lotImage.path = existingLotDetails.lot_image_path;
        this.lotImage.thumbnailPath =
          existingLotDetails.thumbnail_lot_image_path;
        this.lotImage.url = existingLotDetails.lot_image_path_url;
        this.lotImage.thumbnailUrl =
          existingLotDetails.thumbnail_lot_image_path_url;
      }
    },

    async fetchAllOrganizations() {
      this.organizations.isLoadingAllOrgs = true;
      const allOrgs: Array<OrganizationUserAuthorization> | null =
        await api.getAuthorizationsForOrgUser(this.getCurrentUserData.id);
      if (allOrgs) {
        this.organizations.allOrgs = allOrgs
          .filter(
            (org) =>
              org.organization_id != null &&
              org.organization_id != process.env.VUE_APP_SUPERADMIN_ORG_ID &&
              org.organization_id != process.env.VUE_APP_TECHNICIAN_ORG_ID
          )
          .sort((a, b) => a.id - b.id);
      }
      this.organizations.isLoadingAllOrgs = false;
    },

    /**
     * Populate autocomplete dropdown from search results list.
     */
    displayPlacesResult(
      predictions: google.maps.places.AutocompletePrediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) {
      console.log("Got search result");
      this.places.isLoading = false;
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        console.log("Error fetching places");
        return;
      }

      this.places.items.splice(0, this.places.items.length);
      for (let prediction of predictions) {
        this.places.items.push(prediction);
      }
    },

    /**
     * Auto-fill selected result details.
     */
    autocompleteSelectedLatLng() {
      console.log("Fetching details");
      if (this.maps.placesService && this.places.selectedItem) {
        this.maps.placesService.getDetails(
          { placeId: this.places.selectedItem.place_id },
          (
            placeResult: google.maps.places.PlaceResult | null,
            status: google.maps.places.PlacesServiceStatus
          ) => {
            if (
              status != google.maps.places.PlacesServiceStatus.OK ||
              !placeResult
            ) {
              console.log("Error fetching selected place coordinates");
              return;
            }
            if (placeResult.name && !this.name && this.name.length <= 0) {
              this.name = placeResult.name;
              this.parkingLotNameChange();
            }
            if (placeResult.formatted_address) {
              this.address = placeResult.formatted_address;
            }
            if (placeResult.geometry?.location) {
              this.lat = Number(placeResult.geometry?.location?.lat());
              this.lng = Number(placeResult.geometry?.location?.lng());
              console.log("Got coordinates", this.lat, this.lng);
            }
          }
        );
      }
    },

    async submitForm() {
      let parkingLotData: ParkingLotCreate = {
        name: this.name,
        address: this.address,
        gps_coordinates: {
          type: "Point",
          coordinates: [this.lng, this.lat],
        } as Point,
        polygon: this.polygon || {
          type: "Polygon",
          coordinates: [
            [
              [this.lng, this.lat],
              [this.lng, this.lat],
              [this.lng, this.lat],
              [this.lng, this.lat],
              [this.lng, this.lat],
            ],
          ],
        },
        run_inference: this.runInference,
        organization_id: this.organizations.selectedOrgId,
        is_private: this.isPrivate,
        is_search_parked_car_owner_enabled: this.enableSearchParkedCarOwner,
        is_notification_on_spot_update_enabled:
          this.enableSpotUpdateNotification,
        max_park_minutes: this.maxParkTimeSeconds
          ? Number(Math.floor(this.maxParkTimeSeconds / 60))
          : null,
        congestion_threshold: this.congestion_threshold
          ? Number(this.congestion_threshold)
          : null,
        tag_names: this.tagNames,

        is_security_feature_enabled: this.isSecurityFeatureEnabled,
        is_illegal_activity_detection_enabled:
          this.illegalActivityDetection.isEnabled,
        illegal_activity_detection_start_time: convertHHmmLocalToUtc(
          this.illegalActivityDetection.startsAt.time
        ),
        illegal_activity_detection_end_time: convertHHmmLocalToUtc(
          this.illegalActivityDetection.endsAt.time
        ),
        illegal_activity_detection_week_start_time:
          this.illegalActivityDetection.startsAt.timesPerDay.map((t) =>
            convertHHmmLocalToUtc(t)
          ),
        illegal_activity_detection_week_end_time:
          this.illegalActivityDetection.endsAt.timesPerDay.map((t) =>
            convertHHmmLocalToUtc(t)
          ),
        illegal_activity_detection_vehicle_count_threshold:
          this.illegalActivityDetection.vehicleCountThreshold,
        is_illegal_activity_detection_set_initial_vehicle_count:
          this.illegalActivityDetection.isAddVehiclesToThresholdEnabled,
        illegal_activity_detection_vehicles_in_lot: this
          .illegalActivityDetection.isAddVehiclesToThresholdEnabled
          ? this.illegalActivityDetection.vehicleCountAtStart
          : 0,

        is_illegal_parking_violation_feature_enabled:
          this.isIllegalParkingViolationFeatureEnabled,
        violation_alert_grace_period_seconds:
          this.violationAlertGracePeriodSeconds,

        is_parking_permit_feature_enabled: this.isParkingPermitFeatureEnabled,
        is_spot_visibility_checking_feature_enabled:
          this.isSpotVisibilityCheckingFeatureEnabled,
        is_double_parking_feature_enabled: this.isDoubleParkingFeatureEnabled,
        double_parking_violation_wait_time_seconds:
          this.doubleParkingViolationWaitTimeSeconds,
        is_ev_charger_violation_feature_enabled:
          this.isEvChargerViolationFeatureEnabled,
        ev_violation_check_interval_minutes:
          this.evViolationCheckIntervalMinutes,
        ev_violation_count_for_alert: this.evViolationCountForAlert,
        is_park_my_car_feature_enabled: this.isParkMyCarEnabled,
        is_show_app_user_info_enabled: this.isParkMyCarEnabled
          ? this.showUsersProfile
          : false,
        is_show_blocking_app_user_info_enabled: this.showBlockingUsersProfile,
        wait_for_user_to_save_spot: this.waitForUserToSave,
        is_accuracy_tracking_feature_enabled:
          this.isAccuracyTrackingFeatureEnabled,
        is_extreme_weather_detection_feature_enabled:
          this.isExtremeWeatherDetectionFeatureEnabled,
        is_garbled_image_detection_feature_enabled:
          this.isGarbledImageDetectionFeatureEnabled,
        camera_offline_alert_delay_threshold_minutes:
          this.cameraOfflineAlertDelayThresholdMinutes,
        inference_heartbeat_interval_seconds:
          this.inferenceHeartbeatIntervalSeconds,
        is_lpr_feature_enabled: this.lpr.isFeatureEnabled,
        is_lpr_alert_list_feature_enabled:
          this.lpr.isLprAlertListFeatureEnabled,
        lpr_number_plate_text_matching_distance_thresh:
          this.lpr.lprNumberPlateTextMatchingDistanceThresh,
        lpr_pair_linking_interval_minutes:
          this.lpr.lprEntryExitPairLinkingIntervalMinutes,
        is_anpr_feature_enabled: this.anpr.isFeatureEnabled,
        is_anpr_feature_visible_to_customers: this.anpr.isVisibleToCustomers,
        anpr_matching_window_interval_minutes:
          this.anpr.matchingWindowIntervalMinutes,
        anpr_inactive_cleanup_interval_minutes:
          this.anpr.inactiveCleanupIntervalMinutes,
        anpr_vehicle_matching_threshold: this.anpr.vehicleMatchingThreshold,
        is_anpr_ignore_duplicate_lp_within_interval_enabled:
          this.anpr.isIgnoreDuplicateLpWithinIntervalEnabled,
        anpr_lpr_event_delay_offset_seconds:
          this.anpr.lprEventDelayOffsetSeconds,
        is_anpr_rectify_delayed_lpr_enabled:
          this.anpr.isRectifyDelayedLprEnabled,

        is_parking_history_visible: this.isParkingHistoryVisible,
        is_pull_apis_feature_enabled: this.isPullApisEnabled,
        is_enforceability_push_apis_feature_enabled:
          this.isEnforceabilityPushApisEnabled,
        is_availability_push_apis_feature_enabled:
          this.isAvailabilityPushApisEnabled,
        is_revenue_feature_enabled: this.isRevenueFeatureEnabled,
        is_sg_connect_feature_enabled: this.isSGConnectFeatureEnabled,
        revenue_options: this.revenueOptions,

        counter_zone_reset_vehicle_count_start_schedule_week_crontab:
          this.zoneVehicleCountReset.startsAt.timesPerDay.map(
            (t, dayIndex) => convertTimeToCrontab(t, String(dayIndex + 1)) // Add 1 to make monday == 1, ..., sunday == 7
          ),

        lot_image_path: this.lotImage.path,
        thumbnail_lot_image_path: this.lotImage.thumbnailPath,
        operator_contact_number: null,

        max_scheduled_reports: 10,
        max_scheduled_reports_recipients: 10,
      };

      console.log("submitting form data", parkingLotData);
      try {
        this.isLoading = true;
        let savedParkingLot;
        if (!this.isUpdatingExistingLot || this.lotId === null) {
          // Create new parking lot
          savedParkingLot = await api.createParkingLot(parkingLotData);
        } else {
          // Update existing parking lot
          let updatedParkingLotData = {
            id: this.lotId,
            ...parkingLotData,
          } as ParkingLotUpdate;
          try {
            savedParkingLot = await api.updateParkingLot(
              this.lotId,
              updatedParkingLotData
            );

            let parkingTimingData = {
              parking_lot_id: this.lotId,
              not_paid_parking_timing: {
                start_from: this.sgConnectLotOptions.parkingTimingStartFrom,
                to: this.sgConnectLotOptions.parkingTimingEndTo,
                overstay_limit: this.sgConnectLotOptions.overstayLimit,
              },
              enforced_parking_rules_timing:
                this.sgConnectLotOptions.enforcedParkingRulesTiming,
              orgID: this.organizations.selectedOrgId,
            };
            if (this.sgConnectLotOptions.paidParkingTimingConfig) {
              this.parkingTimingApi(parkingTimingData);
            }
          } catch (error) {
            if (error.response.status === 400) {
              this.$dialog.message.error(error.response.data.detail, {
                position: "top-right",
                timeout: 3000,
              });
              this.isLoading = false;
              return;
            }
          }
        }
        if (savedParkingLot) {
          console.log("Saved parking lot with response", savedParkingLot);
          this.$dialog.message.info("Parking lot saved successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.emitCloseForm();

          // update localstorage lotNames
          let lotNames: any = localStorage.getItem("lotNames");
          if (lotNames && savedParkingLot) {
            lotNames = JSON.parse(lotNames);
            if (lotNames && lotNames[savedParkingLot.id]) {
              lotNames[savedParkingLot.id] = savedParkingLot.name;
              localStorage.setItem("lotNames", JSON.stringify(lotNames));
            }
          }

          this.$emit("refresh-data");
        }
      } catch (e) {
        console.log("Failed to save parking lot", e);
        this.$dialog.message.info("Error saving parking lot", {
          position: "top-right",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },

    resetForm() {
      this.lotId = null;
      this.name = "";
      this.address = "";
      this.lat = 0;
      this.lng = 0;
      this.polygon = null;
      this.max_park_minutes = null;
      this.congestion_threshold = null;
      this.runInference = false;
      this.isPrivate = false;
      this.enableSearchParkedCarOwner = false;
      this.enableSpotUpdateNotification = false;
      this.places.searchText = "";
      this.places.selectedItem = null;
      this.organizations.selectedOrgId = 0;
      this.tagNames = [];

      this.isSecurityFeatureEnabled = false;
      this.illegalActivityDetection.isEnabled = false;
      this.illegalActivityDetection.startsAt.time = "";
      this.illegalActivityDetection.endsAt.time = "";
      this.illegalActivityDetection.startsAt.timesPerDay = Array(7).fill("");
      this.illegalActivityDetection.endsAt.timesPerDay = Array(7).fill("");
      this.illegalActivityDetection.vehicleCountThreshold = 10;
      this.illegalActivityDetection.isAddVehiclesToThresholdEnabled = false;
      this.illegalActivityDetection.vehicleCountAtStart = 0;
      this.isParkMyCarEnabled = false;
      (this.isParkingPermitFeatureEnabled = false),
        (this.isSpotVisibilityCheckingFeatureEnabled = false),
        (this.isAccuracyTrackingFeatureEnabled = false),
        (this.isExtremeWeatherDetectionFeatureEnabled = false),
        (this.isGarbledImageDetectionFeatureEnabled = false),
        (this.isParkingHistoryVisible = false);
      this.isPullApisEnabled = false;
      this.isEnforceabilityPushApisEnabled = false;
      this.isAvailabilityPushApisEnabled = false;
      this.isAccuracyTrackingFeatureEnabled = false;
      this.lpr.isFeatureEnabled = false;
      this.lpr.selectedBlacklistWhitelistMode = null;
      this.anpr.isFeatureEnabled = false;
      this.anpr.matchingWindowIntervalMinutes = 10;
      this.anpr.inactiveCleanupIntervalMinutes = 10;
      this.showCameraROIWarning = false;
      this.zoneVehicleCountReset.startsAt.time = "";
      this.zoneVehicleCountReset.startsAt.timesPerDay = Array(7).fill("");
      this.zoneVehicleCountReset.startsAt.showField = true;

      this.removeLotImage();
      this.clearParkingTime();

      (this.$refs.parkingLotFormElm as VForm).resetValidation();
    },

    getTimeFromSeconds(seconds: number) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      return [hours, minutes, remainingSeconds];
    },

    changedParkingTime(unit: string) {
      if (unit === "hours" && this.parkingTime.hours > 23) {
        this.parkingTime.hours = 23;
      }
      if (unit === "hours" && String(this.parkingTime.hours) == "") {
        this.parkingTime.hours = 0;
      }
      if (unit === "minutes" && this.parkingTime.minutes > 59) {
        this.parkingTime.minutes = 59;
      }
      if (unit === "minutes" && String(this.parkingTime.minutes) == "") {
        this.parkingTime.minutes = 0;
      }
      if (unit === "seconds" && this.parkingTime.seconds > 59) {
        this.parkingTime.seconds = 59;
      }
      if (unit === "seconds" && String(this.parkingTime.seconds) == "") {
        this.parkingTime.seconds = 0;
      }
    },

    clearParkingTime() {
      this.parkingTime.hours = 0;
      this.parkingTime.minutes = 0;
      this.parkingTime.seconds = 0;
    },

    async onLotImageChanged(e: any) {
      if (e == null || e.target.files.length != 1 || this.lotId == null) {
        this.$dialog.message.error(
          "Lot Image Unchanged, please select an image to be uploaded.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      let selectedImageFile = e.target.files[0];
      this.lotImage.isUploading = true;
      let uploadDetails = await api.uploadParkingLotImage(
        this.lotId,
        selectedImageFile
      );

      if (uploadDetails && uploadDetails.thumbnail_lot_image_path) {
        console.log("Upload details", uploadDetails);
        this.lotImage.path = uploadDetails.lot_image_path;
        this.lotImage.thumbnailPath = uploadDetails.thumbnail_lot_image_path;
        this.lotImage.url = uploadDetails.lot_image_path_url;
        this.lotImage.thumbnailUrl = uploadDetails.thumbnail_lot_image_path_url;
      } else {
        this.$dialog.message.error(
          "Upload Error. Lot Image Unchanged, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }

      this.lotImage.isUploading = false;
    },

    removeLotImage() {
      this.lotImage.path = null;
      this.lotImage.isUploading = false;
      this.lotImage.thumbnailPath = null;
      this.lotImage.url = null;
      this.lotImage.thumbnailUrl = null;
    },

    /**
     * Display image in given url inside a fullscreen zoomable/pannable image viewer.
     */
    showImageInViewer(url: string) {
      this.$viewerApi({
        images: [url],
      });
    },

    emitCloseForm(withResetForm = true) {
      if (withResetForm) {
        this.resetForm();
      }
      this.$emit("close-form");
    },

    parkingLotNameChange: _.debounce(async function (this: any) {
      if (!this.isUpdatingExistingLot && this.name) {
        let is_not_unique_name = await api.checkParkingLotName({
          parking_lot_name: this.name,
        });
        if (is_not_unique_name != null && is_not_unique_name) {
          this.uniqueParkingLotNameError = true;
        } else {
          this.uniqueParkingLotNameError = false;
        }
      }
    }, 1000),

    /**
     * Disable all individual features if security is disabled entirely.
     */
    onIsSecurityFeatureEnabledChanged(isEnabled: boolean) {
      if (!isEnabled) {
        this.illegalActivityDetection.isEnabled = false;
      }
    },

    /**
     * When LPR feature is enabled, also enable Alert List feature.
     * When LPR feature is disabled, also disable ANPR feature and Alert List.
     */
    onEnableLprFeatureChanged(isEnabled: boolean) {
      if (isEnabled) {
        this.lpr.isLprAlertListFeatureEnabled = true;
      } else {
        this.lpr.isLprAlertListFeatureEnabled = false;
        this.anpr.isFeatureEnabled = false;
        this.anpr.isVisibleToCustomers = false;
      }
    },

    /**
     * When enabling any feature that requires vehicle count, show a popup saying that
     * ROI needs to be drawn on the cameras.
     */
    onToggleVehicleRoiCountFeature(isEnabled: boolean) {
      if (isEnabled) {
        this.$dialog.message.info(
          "Please ensure that ROI polygon is drawn on the camera maps to use this feature.",
          {
            position: "top-right",
            timeout: 6000,
          }
        );
      }
    },

    trimAmountTwoDecimals() {
      if (
        this.revenueOptions.minimum.amount < 0 ||
        this.revenueOptions.increments.amount < 0 ||
        this.revenueOptions.hourly_amount < 0 ||
        this.revenueOptions.daily_amount < 0 ||
        this.revenueOptions.daily_max_amount < 0
      ) {
        this.revenueOptionsError = "*Amount cannot be less than 0";
      } else {
        this.revenueOptionsError = null;
      }

      if (
        this.revenueOptions.violationFines.non_payment < 0 ||
        this.revenueOptions.violationFines.overstay < 0 ||
        this.revenueOptions.violationFines.illegal_parking < 0 ||
        this.revenueOptions.violationFines.permit < 0
      ) {
        this.violationFinesError = "*Amount cannot be less than 0";
      } else {
        this.violationFinesError = null;
      }
    },
    resetAnprFeatureOptions() {
      if (!this.anpr.isFeatureEnabled) {
        this.anpr.isVisibleToCustomers = false;
      }
    },
    showPaidParkingTimingConfig() {
      this.sgConnectLotOptions.paidParkingTimingConfig =
        !this.sgConnectLotOptions.paidParkingTimingConfig;
    },
  },

  watch: {
    /**
     * Search for entered search query whenever the field value changes.
     */
    "places.searchText"(searchText) {
      if (this.maps.autoCompleteService && searchText) {
        this.places.isLoading = true;
        this.maps.autoCompleteService.getPlacePredictions(
          { input: this.places.searchText },
          this.displayPlacesResult
        );
      }
    },
    existingLotDetails(newDetails) {
      if (newDetails) {
        this.initFormWithLotDetails(newDetails);
      }
    },
    needsInit(show) {
      if (show) {
        this.initFormWithLotDetails(this.existingLotDetails);
      } else {
        this.resetForm();
      }
    },

    // Time fields for illegalActivityDetection for all days of the week
    "illegalActivityDetection.startsAt.time"(newStartTime) {
      if (this.illegalActivityDetection.sameTimeOnAllDays) {
        this.illegalActivityDetection.startsAt.timesPerDay =
          Array(7).fill(newStartTime);
      } else {
        this.illegalActivityDetection.startsAt.timesPerDay[
          this.illegalActivityDetection.selectedDayIndex
        ] = newStartTime;
      }
    },

    "illegalActivityDetection.endsAt.time"(newEndTime) {
      if (this.illegalActivityDetection.sameTimeOnAllDays) {
        this.illegalActivityDetection.endsAt.timesPerDay =
          Array(7).fill(newEndTime);
      } else {
        this.illegalActivityDetection.endsAt.timesPerDay[
          this.illegalActivityDetection.selectedDayIndex
        ] = newEndTime;
      }
    },

    "illegalActivityDetection.selectedDayIndex"(newSelectedDayIndex) {
      if (!this.illegalActivityDetection.sameTimeOnAllDays) {
        this.illegalActivityDetection.startsAt.time =
          this.illegalActivityDetection.startsAt.timesPerDay[
            newSelectedDayIndex
          ];
        this.illegalActivityDetection.endsAt.time =
          this.illegalActivityDetection.endsAt.timesPerDay[newSelectedDayIndex];
      }
    },

    "illegalActivityDetection.sameTimeOnAllDays"(newSameTimeOnAllDays) {
      if (newSameTimeOnAllDays) {
        this.illegalActivityDetection.startsAt.timesPerDay = Array(7).fill(
          this.illegalActivityDetection.startsAt.time
        );
        this.illegalActivityDetection.endsAt.timesPerDay = Array(7).fill(
          this.illegalActivityDetection.endsAt.time
        );
      }
    },

    /**
     * Make field value null instead of empty string since the data type of this
     * field is either number or null.
     */
    cameraOfflineAlertDelayThresholdMinutes(newVal) {
      if (newVal === "") {
        this.cameraOfflineAlertDelayThresholdMinutes = null;
      }
    },

    // Time fields for zoneVehicleCountReset
    "zoneVehicleCountReset.startsAt.time"(newStartTime) {
      if (this.zoneVehicleCountReset.sameTimeOnAllDays) {
        this.zoneVehicleCountReset.startsAt.timesPerDay =
          Array(7).fill(newStartTime);
      } else {
        this.zoneVehicleCountReset.startsAt.timesPerDay[
          this.zoneVehicleCountReset.selectedDayIndex
        ] = newStartTime;
      }
    },

    "zoneVehicleCountReset.selectedDayIndex"(newSelectedDayIndex) {
      if (!this.zoneVehicleCountReset.sameTimeOnAllDays) {
        this.zoneVehicleCountReset.startsAt.time =
          this.zoneVehicleCountReset.startsAt.timesPerDay[newSelectedDayIndex];
      }
    },

    "zoneVehicleCountReset.sameTimeOnAllDays"(newSameTimeOnAllDays) {
      if (newSameTimeOnAllDays) {
        this.zoneVehicleCountReset.startsAt.timesPerDay = Array(7).fill(
          this.zoneVehicleCountReset.startsAt.time
        );
      }
    },

    isParkMyCarEnabled(newVal) {
      if (!newVal) {
        this.showUsersProfile = false;
        this.waitForUserToSave = 5;
      }
    },
  },
});
