import { ParkingLot, SessionData } from "@/api/models";
import sgconnectapis from "@/api/sgconnectapis";

const state = {
  currentParkingLotData: null as ParkingLot | null,
  sgConnectToken: "",
};

const getters = {
  getCurrentParkingLotData(state: SessionData): ParkingLot | null {
    return state.currentParkingLotData;
  },
};

const mutations = {
  setSgConnectToken(sgConnectToken: string): void {
    state.sgConnectToken = sgConnectToken;
  },
};

const actions = {
  async generateTokenApi(context: any, org_id: any): Promise<void> {
    const response = await sgconnectapis.generateToken(org_id);
    context.commit("setSgConnectToken", response?.token ?? "");
  },

  async parkingTimingApi(context: any, req_data: any): Promise<any | null> {
    const response = await sgconnectapis.parkingTiming(req_data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
