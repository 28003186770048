import axios from "axios";
import { sgConnectApi } from "../main";
import type {
  ApiToken,
  parkingTimingData,
} from "@/api/models/SgconnectIntegration";

export default {
  async generateToken(org_id: number | string): Promise<ApiToken | null> {
    try {
      const formData = new FormData();
      formData.append("org_id", org_id as string);
      const response = await axios.post(`/v1/oauth/token-sgconnect`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const sgToken = response.data.data.find((v: ApiToken) => v.token);
      return sgToken;
    } catch (error: any) {
      return error;
    }
  },

  async parkingTiming(req_data: any): Promise<parkingTimingData | null> {
    try {
      const orgID = req_data.orgID;
      delete req_data.orgID;
      const response = await sgConnectApi.post(
        `/v1/parking/timing/not-paid`,
        req_data,
        {
          headers: {
            organization: orgID,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return error;
    }
  },
};
